import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import style from "./image.module.css"

const Image = ({ slug, size, position, invertColors }) => {
	const classes = [style.image]
	if (size) classes.push(style[size])
	if (position) classes.push(style[position])
	if (invertColors) classes.push(style.invert)
	const image = useImage(slug)
	if (image) return <Img className={classes.join(" ")} fixed={image.fixed} />
	else {
		console.warn("Missing image: " + slug)
		return null
	}
}

function useImage(slug) {
	const { images } = useStaticQuery(
		graphql`
			query {
				images: allImageSharp(
					filter: { fields: { collection: { eq: "content-images" } } }
				) {
					edges {
						node {
							parent {
								... on File {
									id
									name
									relativePath
								}
							}
							fixed(width: 300) {
								base64
								src
								srcSet
								height
								width
							}
						}
					}
				}
			}
		`
	)
	return images.edges.map(({ node }) => node).filter(node => node.parent.name === slug)[0]
}

export default Image
