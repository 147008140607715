import React from "react"
import { graphql } from "gatsby"
import { useStaticQuery } from "gatsby"

import RenderHtml from "./renderHtml"

const Snippet = ({ id }) => {
	if (!id) throw new Error("Can't find snippet without id.")
	const { snippets } = useStaticQuery(
		graphql`
			query {
				snippets: allMarkdownRemark(
					filter: { fields: { collection: { eq: "snippets" } } }
				) {
					edges {
						node {
							fields {
								id
							}
							htmlAst
						}
					}
				}
			}
		`
	)

	const snippet = findSnippet(snippets, id)
	if (!snippet) throw new Error("Unknown snippet: " + id)
	return <RenderHtml withAst={snippet} />
}

function findSnippet(snippets, id) {
	return snippets.edges.map(snippet => snippet.node).filter(node => node.fields.id === id)[0]
}

export default Snippet
