import React from "react"

import Link from "./link"
import Image from "./image"

import cardStyle from "./card.module.css"
import imageStyle from "./image.module.css"

const Card = ({ size, imageSlug, imageLink, invertColors, fillVertical, className, children }) => {
	const classes = [cardStyle.card, imageStyle[size]]
	if (invertColors) classes.push(cardStyle.invert)
	if (fillVertical) classes.push(cardStyle.fillVertical)
	if (className) classes.push(className)

	const image = <Image slug={imageSlug} size={size} invertColors={invertColors}/>
	return (
		<div className={classes.join(" ")}>
			{imageLink ? <div className={cardStyle.imageLink}><Link to={imageLink}>{image}</Link></div> : image}
			{children && <div className={cardStyle.text}>{children}</div> }
		</div>
	)
}

export default Card
