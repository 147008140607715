import React from "react"

import Meta from "./meta.js"
import Header from "./header.js"
import Footer from "./footer.js"

// set up FontAwesome here, so it can be freely used
import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { fas } from "@fortawesome/free-solid-svg-icons"

import { flatten } from "../infra/functions"

import style from "./layout.module.css"

library.add(fas, fab)

const Layout = ({ meta, children, wide }) => (
	<React.Fragment>
		<Meta meta={meta} />
		<Header />
		<main>
			{flatten(children).map(child => (
				<div key={child.key} className={`segment ${wide && style.wide}`}>
					<div className={style.decoration}>
						<div className={style.content}>{child}</div>
					</div>
				</div>
			))}
		</main>
		<Footer />
	</React.Fragment>
)

export default Layout
