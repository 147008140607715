import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Link from "./link"

import style from "./logo.module.css"

const Logo = () => (
	<StaticQuery
		query={graphql`
			query {
				logo: file(relativePath: { eq: "logo.png" }) {
					childImageSharp {
						fixed(width: 540) {
							base64
							src
							srcSet
							height
							width
						}
					}
				}
			}
		`}
		render={data => (
			<div className={style.container}>
				<Link to="/">
					<div className={style.position}>
						<Img className={style.logo} fixed={data.logo.childImageSharp.fixed} />
					</div>
				</Link>
			</div>
		)}
	/>
)
export default Logo
