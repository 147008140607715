import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import External from "./external.js"

import style from "./powered.module.css"

const Powered = ({ white }) => (
	<div className={style.container}>
		<External to="https://www.disy.net/en/">
			<div>
				<Img className={style.powered} fixed={useImage(white)} />
			</div>
		</External>
	</div>
)

function useImage(white) {
	const { powered, poweredWhite } = useStaticQuery(
		graphql`
			query {
				powered: file(relativePath: { eq: "powered-disy.png" }) {
					childImageSharp {
						fixed(width: 92) {
							base64
							src
							srcSet
							height
							width
						}
					}
				}
				poweredWhite: file(relativePath: { eq: "powered-disy-white.png" }) {
					childImageSharp {
						fixed(width: 92) {
							base64
							src
							srcSet
							height
							width
						}
					}
				}
			}
		`
	)
	return (white ? poweredWhite : powered).childImageSharp.fixed
}

export default Powered
