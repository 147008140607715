import React from "react"
import { graphql } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Link from "./link"

export default ({ social }) => (
	<React.Fragment>
		{site(social)}
		{twitter(social)}
	</React.Fragment>
)

function site(social) {
	return (
		social.site && (
			<Link className="social" to={social.site}>
				<FontAwesomeIcon icon={["fas", "globe"]} />
			</Link>
		)
	)
}

function twitter(social) {
	return (
		social.twitter && (
			<Link className="social" to={`https://twitter.com/${social.twitter}`}>
				<FontAwesomeIcon icon={["fab", "twitter"]} />
			</Link>
		)
	)
}

export const eventFragment = graphql`
	fragment Social on MarkdownRemarkFrontmatter {
		social {
			site
			twitter
		}
	}
`
