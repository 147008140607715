import React from "react"

import Link from "./link"

export default ({ event, linkTitle, className }) => (
	<React.Fragment>
		{linkTitle
			? <Link to={event.fields.path} className={className}>{event.frontmatter.title}</Link>
			: <span className={className}>{event.frontmatter.title}</span> }
		{event.frontmatter.language && <span> [{event.frontmatter.language}]</span>}
	</React.Fragment>
)
