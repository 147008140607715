import React from "react"

const External = ({ to, className, children, newTab }) => {
	className = className ? className : ""
	return newTab ? (
		<a className={className} target="_blank" rel="noopener noreferrer" href={to}>
			{children}
		</a>
	) : (
		<a className={className} href={to}>
			{children}
		</a>
	)
}

export default External
