import React from "react"
import Link from "./link"

import Social from "./social"

import "./speakerName.css"

export default ({ speaker, linkName, showSocial }) => (
	<React.Fragment>
		{linkName ? (
			<Link to={speaker.fields.path}>{speaker.frontmatter.name}</Link>
		) : (
			speaker.frontmatter.name
		)}
		{showSocial && speaker.frontmatter.social && (
			<span className="socials">
				<Social social={speaker.frontmatter.social} />
			</span>
		)}
	</React.Fragment>
)
