import React from "react"
import { Link as Internal } from "gatsby"

import External from "./external"

const Link = ({ to, className, children }) => {
	// regular URLs and mailto links are external
	const external = to.indexOf("://") > 0 || to.indexOf("//") === 0 || to.startsWith("mailto")
	// but links to another Accento site (e.g. https://2019.accento.dev/...),
	// while external, should not be opened in a new tab
	const newTab = !to.match(/https+:\/\/(\d{4}\.)*accento\.dev/)
	return external ? (
		<External to={to} newTab={newTab} className={className}>
			{children}
		</External>
	) : (
		<Internal to={to} className={className}>
			{children}
		</Internal>
	)
}

export default Link
