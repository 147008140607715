import React, { useEffect } from "react"

import { classNames } from "../infra/functions"

import Link from "./link"

import style from "./siteMenu.module.css"

import json from "../../content/meta/siteMenu.json"

const SiteMenu = ({ className }) => {
	useEffect(() => {
		window.addEventListener("click", closeOpenMenus)
		return () => window.removeEventListener("click", closeOpenMenus)
	})
	return (
		<nav {...classNames(className, style.menu)}>
			<input id={style.menuToggle} type="checkbox" />
			<label className={style.menuButtonContainer} htmlFor={style.menuToggle}>
				<div className={style.menuButton} />
			</label>
			<div className={style.topLevelContainer}>
				{json.entries.map(entry => topLevelEntry(entry))}
			</div>
		</nav>
	)
}

const topLevelEntry = ({ title, url, children, className }) => {
	const id = ("site-nav-top-level-entry-" + Math.random()).replace("0.", "")
	const checkboxId = id + "-checkbox"
	const contentId = id + "-content"
	if (url)
		return (
			<div key={title} className={style.topLevelEntry}>
				<Link className={className} to={url}>
					{title}
				</Link>
			</div>
		)
	if (children)
		return (
			<div key={title} className={style.topLevelEntry}>
				<input id={checkboxId} className={style.topLevelCheckbox} type="checkbox" />
				<label {...classNames(style.topLevelLabel, className)} htmlFor={checkboxId}>
					{title}
				</label>
				<div id={contentId} className={style.secondLevelOuterContainer}>
					<div className={style.secondLevelContainer}>
						{children.map(entry => secondLevelEntry(entry))}
					</div>
				</div>
			</div>
		)
	throw new Error(`Nav entry "${title}" with neither URL nor children.`)
}

const secondLevelEntry = ({ title, url, children, className }) => {
	if (url)
		return (
			<div key={title} className={style.secondLevelEntry}>
				<Link className={className} to={url}>
					{title}
				</Link>
			</div>
		)
	throw new Error(`Nav entry "${title}" without URL.`)
}

/*
 * JavaScript
 */

const closeOpenMenus = event => {
	document
		.querySelectorAll(`.${style.topLevelCheckbox}:checked`)
		.forEach(checkbox => closeOpenMenu(event, checkbox))
}

const closeOpenMenu = (event, checkbox) => {
	const target = event.target
	const clickedLabel = target.nodeName.toLowerCase() === "label" && target.htmlFor === checkbox.id
	// if the label was clicked, ignore the event;
	// another one will be coming that otherwise toggles back to true
	if (clickedLabel) return

	// if either the checkbox or the div was clicked, we do nothing
	const clickedMenuEntry = checkbox.parentNode.contains(event.target)
	if (clickedMenuEntry) return

	// something unrelated to the currently open menu was clicked ~> close it
	// (by simulating a click, so the animations are triggered)
	checkbox.click()
}

export default SiteMenu
